import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Box from "@components/styled/Box"
import Container from "@components/styled/Container"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { rem } from "@src/theme"
import EventCard from "@components/EventCard"
import Columns from "@components/styled/Columns"
import Column from "@components/styled/Column"
import SelectInput from "@components/Select"
import { fetchEvents } from "@helpers/airtable"
import TextInput from "@components/TextInput"
import { SingleDatePicker } from "react-dates"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
// ****************//
import FilterActionButtons from "@components/FilterActionButtons"
import {
  getValueFromId,
  mapOptions,
  getFilterCallbacks,
  getFilterOptions,
  getUnselectedFilterOptions
} from "@helpers/data"

import { ClipLoader } from "react-spinners"
import { authCheckRedirect } from "@helpers/auth"
import AddButton from "@components/AddButton"

// ****************//
const FILTER_BY_OPTIONS = [
  { value: "name", label: "Event name" },
  { value: "organisation", label: "Organisation" },
  { value: "format", label: "Format" },
  { value: "date", label: "End date" },
  { value: "state", label: "State" },
  { value: "attendees", label: "Attendees" }
]

const ViewEvents = ({ data, ...props }) => {
  const [recordsData, setRecordsData] = useState([])
  // ****************//
  const [filters, setFilters] = useState([{ type: "name", value: "" }])
  const [loading, setLoading] = useState(false)
  const [publishedCalendarFocused, setPublishedCalendarFocused] = useState(
    false
  )

  const eventTypeOptions = mapOptions(data.airtable_eventType.edges)

  const stateTerritoryOptions = mapOptions(data.airtable_stateTerritory.edges)
  const eventFormatOptions = mapOptions(data.airtable_eventFormat.edges)

  useEffect(() => {
    authCheckRedirect()

    const fetchData = async () => {
      const {
        airtable_eventType,
        airtable_city,
        airtable_eventFormat,
        airtable_stateTerritory
      } = data
      setLoading(true)
      let dataFromAirtable = await fetchEvents()
      dataFromAirtable = dataFromAirtable.map(record => ({
        id: record.id,
        name: record.fields["Event name"] || "",
        presentation_name: record.fields["Presentation name"] || "",
        type: getValueFromId(
          airtable_eventType.edges,
          record.fields["Event Type"]
        ),
        city: getValueFromId(airtable_city.edges, record.fields["City"]),
        format: getValueFromId(
          airtable_eventFormat.edges,
          record.fields["Format"]
        ),
        state: getValueFromId(
          airtable_stateTerritory.edges,
          record.fields["State / Territory"]
        ),
        organisation: record.fields["Organisation"] || "",
        date: record.fields["End Date"] || "",
        attendees: record.fields["Attendees"] || ""
      }))
      setRecordsData(dataFromAirtable)
      setLoading(false)
    }

    fetchData()
  }, [])

  // ****************//
  const onFilterByChange = (val, index) => {
    let updatedFilters = [...filters]

    updatedFilters[index] = {
      type: val,
      value: ""
    }
    setFilters(updatedFilters)
  }
  // ****************//
  const onFilterChoiceChange = (val, index) => {
    let updatedFilters = [...filters]

    updatedFilters[index] = {
      ...updatedFilters[index],
      value: val
    }
    setFilters(updatedFilters)
  }
  // ****************//
  const handleTextFilterChange = (val, index) => {
    onFilterChoiceChange(val, index)
  }
  // ****************//
  const handleDropdownFilterChange = (val, index) => {
    onFilterChoiceChange(val, index)
  }
  // ****************//
  const getDropdownOption = type => {
    if (type === "type") {
      return eventTypeOptions
    }
    if (type === "state") {
      return stateTerritoryOptions
    }
    if (type === "format") {
      return eventFormatOptions
    }

    return []
  }
  // ****************//
  const addFilter = () => {
    const availableOptions = getUnselectedFilterOptions(
      filters,
      FILTER_BY_OPTIONS
    )
    setFilters([...filters, { type: availableOptions[0].value, value: "" }])
  }
  // ****************//
  const clearFilters = () => {
    setFilters([{ type: "name", value: "" }])
  }
  // ****************//
  const renderFilterInput = (type, value, index) => {
    if (type === "attendees") {
      return (
        <TextInput
          type="number"
          value={value}
          onChange={e => handleTextFilterChange(e.target.value, index)}
          onBlur={() => {}}
        />
      )
    }

    if (type === "date") {
      return (
        <SingleDatePicker
          id="publishedDate_input"
          date={value}
          focused={publishedCalendarFocused}
          onDateChange={e => handleTextFilterChange(e, index)}
          onFocusChange={e =>
            setPublishedCalendarFocused(!publishedCalendarFocused)
          }
          numberOfMonths={1}
          isOutsideRange={() => false}
        />
      )
    }

    if (
      type === "name" ||
      type === "presentation_name" ||
      type === "organisation"
    ) {
      return (
        <div>
          <label htmlFor="name-filter"></label>
          <TextInput
            name="name-filter"
            value={value}
            onChange={e => handleTextFilterChange(e.target.value, index)}
            onBlur={() => {}}
          />
        </div>
      )
    }

    return (
      <SelectInput
        name={type}
        value={value}
        onChange={(name, val) => handleDropdownFilterChange(val, index)}
        options={getDropdownOption(type)}
      />
    )
  }

  return (
    <Layout uri={props.uri}>
      <SEO title="Home" />

      <Container pt={7}>
        <Box
          as="h1"
          fontSize={rem(28)}
          mb={4}
          p={4}
          pr={6}
          bg="blue"
          color="white"
          display="inline-block"
        >
          Events
        </Box>

        <Box as="section" my={6}>
          <Box display="flex" alignItems="flex-end">
            {/* ***************** */}
            <Box mr={5}>
              <Box display="flex" alignItems="center" mb={3}>
                <Box as="label" display="block" mr={4}>
                  Filter by
                </Box>

                <FilterActionButtons
                  onAdd={addFilter}
                  onClear={clearFilters}
                  addDisabled={filters.length === FILTER_BY_OPTIONS.length}
                />
              </Box>

              {filters.map(({ type, value }, index) => (
                <Box width="100%" display="flex" mb={5}>
                  <Box width={rem(300)} mr={5}>
                    <SelectInput
                      name={type}
                      value={type}
                      onChange={(name, val) => onFilterByChange(val, index)}
                      options={getFilterOptions(filters, FILTER_BY_OPTIONS)}
                    />
                  </Box>
                  <Box width={rem(300)}>
                    {renderFilterInput(type, value, index)}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <AddButton to="/add-event" />

        <Columns>
          {/* ***************** */}
          {recordsData.length ? (
            //
            getFilterCallbacks(filters, recordsData, [
              "name",
              "presentation_name",
              "organisation"
            ])
              .reduce((d, f) => d.filter(f), recordsData)
              .map(record => (
                <Column key={record.id} width={[1, 1 / 2]} mb={2}>
                  <EventCard {...record} />
                </Column>
              ))
          ) : !loading && !recordsData.length ? (
            <Column width={1}>
              <Box textAlign="center">
                There aren't any events, add one{" "}
                <Link to="add-event">here</Link>
              </Box>
            </Column>
          ) : (
            <Column width={1}>
              <Box display="flex" justifyContent="center">
                <ClipLoader sizeUnit="px" size={150} />
              </Box>
            </Column>
          )}
        </Columns>
      </Container>
    </Layout>
  )
}

export default ViewEvents

export const query = graphql`
  query {
    airtable_eventType: allAirtable(
      filter: { table: { eq: "DL_Event_Type" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
    airtable_stateTerritory: allAirtable(
      filter: { table: { eq: "DL_State_Territory" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    airtable_city: allAirtable(filter: { table: { eq: "DL_City" } }) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    airtable_eventFormat: allAirtable(
      filter: { table: { eq: "DL_Event_Format" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }

    airtable_stateTerritory: allAirtable(
      filter: { table: { eq: "DL_State_Territory" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
  }
`
